import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, Toolbar, Typography, IconButton } from "@mui/material";
import { IoMdArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";

function NurseChat() {
  const navigate = useNavigate();
  
  const menuItems = [
    { 
      title: 'Setup', 
      onClick: () => navigate('/dashboard/nurse-chat/setup')
    },
    { 
      title: 'Assessments', 
      onClick: () => navigate('/dashboard/nurse-chat/assessments')
    },
    { 
      title: 'Answers', 
      onClick: () => navigate('/dashboard/nurse-chat/answers')
    },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar>
        <Toolbar>
          <Link to={"/dashboard"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Nurse Chat
          </Typography>
        </Toolbar>
      </AppBar>

      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="w-full max-w-md bg-white rounded-lg shadow p-6 mt-14">
          <h1 className="text-2xl font-semibold text-center mb-6">
            Nurse Chat
          </h1>
          
          <div className="space-y-4">
            {menuItems.map((item, index) => (
              <div
                key={index}
                onClick={item.onClick}
                className="flex items-center justify-between rounded-md bg-white border border-gray-200 p-4 cursor-pointer hover:shadow-sm transition-shadow"
              >
                <span className="text-lg font-medium">{item.title}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M9 5l7 7-7 7" 
                  />
                </svg>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Box>
  );
}

export default NurseChat; 