import React, { useContext, useEffect, useState } from 'react';
import { AppBar, Box, Toolbar, Typography, IconButton } from "@mui/material";
import { IoMdArrowBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../../Context/AuthContext';
import axios from 'axios';
import { FaCalendarAlt } from 'react-icons/fa';

function NurseChatAnswers() {
  const navigate = useNavigate();
  const { isAuthenticated, token } = useContext(AuthContext);
  const [assessments, setAssessments] = useState([]);
  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const fetchAnswers = async () => {
    if (isAuthenticated) {
      try {
        const response = await axios.get(
          import.meta.env.VITE_APP_BASE_API + import.meta.env.VITE_APP_API_VERSION + "/user/patient/nurse-chat/answers",
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAssessments(response.data.assessments);
        setFilteredAssessments(response.data.assessments);
      } catch (error) {
        console.error("Error fetching answers:", error);
      }
    }
  };

  useEffect(() => {
    fetchAnswers();
  }, [isAuthenticated]);

  const getProName = (name) => {
    if (name === 'preprocedure') {
      return 'Pre Procedure';
    }
    if (name === 'postprocedure') {
      return 'Post Procedure';
    } else {
      return name;
    }
  };

  function convertToHumanReadable(dateString) {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    const hours = dateString.slice(8, 10);
    const minutes = dateString.slice(10, 12);

    const date = new Date(year, month - 1, day, hours, minutes);
    const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleString('en-US', options);
  }

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleSearch = () => {
    if (fromDate && toDate) {
      const filtered = assessments.filter((assessment) => {
        const timestamp = new Date(
          parseInt(assessment.timestamp.slice(0, 4)),
          parseInt(assessment.timestamp.slice(4, 6)) - 1,
          parseInt(assessment.timestamp.slice(6, 8)),
          parseInt(assessment.timestamp.slice(8, 10)),
          parseInt(assessment.timestamp.slice(10, 12))
        );
        const from = new Date(fromDate);
        const to = new Date(toDate);
        return timestamp >= from && timestamp <= to;
      });
      setFilteredAssessments(filtered);
    }
  };

  const handleNavigate = (item) => {
    localStorage.setItem('selectedQuestion', JSON.stringify(item.data));
    navigate(`/dashboard/nurse-chat/answers/${item._id}`);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar>
        <Toolbar>
          <Link to={"/dashboard/nurse-chat"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ mr: 2 }}
            >
              <IoMdArrowBack />
            </IconButton>
          </Link>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Nurse Chat Answers
          </Typography>
        </Toolbar>
      </AppBar>

      <div className="min-h-screen flex flex-col items-center bg-gray-100 p-5">
        <div className="w-full max-w-4xl p-6 bg-blue-200 rounded-lg shadow-lg mt-[100px]">
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div>
              <label className="block mb-2">From</label>
              <div className="flex items-center border border-gray-300 rounded-lg p-2 bg-white">
                <FaCalendarAlt className="text-gray-400 mr-2" />
                <input type="date" className="flex-1 outline-none" value={fromDate} onChange={handleFromDateChange} />
              </div>
            </div>
            <div>
              <label className="block mb-2">To</label>
              <div className="flex items-center border border-gray-300 rounded-lg p-2 bg-white">
                <FaCalendarAlt className="text-gray-400 mr-2" />
                <input type="date" className="flex-1 outline-none" value={toDate} onChange={handleToDateChange} />
              </div>
            </div>
          </div>
          <button className="w-full py-2 bg-red-500 text-white rounded-lg shadow-md" onClick={handleSearch}>
            SEARCH
          </button>
        </div>

        <div className="mt-6 w-full max-w-4xl">
          {filteredAssessments.map((item, index) => (
            <div 
              key={index} 
              onClick={() => handleNavigate(item)}
              className="flex items-center justify-between p-4 bg-gradient-to-r from-blue-500 to-blue-300 rounded-lg shadow-md mb-4 cursor-pointer"
            >
              <div className="text-white font-bold">
                {getProName(item.assessment_name)}
              </div>
              <div className="text-white">{convertToHumanReadable(item.timestamp)}</div>
              <button className="text-white font-bold">
                &gt;
              </button>
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
}

export default NurseChatAnswers; 