import { useContext, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Appointment from "./Pages/Appointment";
import AboutUs from "./Pages/AboutUs";
import Register from "./Pages/Register";
import Footer from "./components/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Login from "./Pages/Login";
import ChatIcon from "./components/ChatIcon";
import { AuthContext } from "./Context/AuthContext";
import Profile from "./Pages/Profile";
import SettingsPage from "./Pages/Settings";
import NotificationPage from "./Pages/Notifications";
import FriendsListPage from "./Pages/FriendListPage";
import EditProfilePage from "./Pages/EditProfilePage";
import UserInformationPage from "./Pages/UserInformationPage";
import MainDashboard from "./Pages/Dashboard/MainDashboard";
import ShareData from "./Pages/Dashboard/ShareData";
import AnswersDashboard from "./Pages/Dashboard/Answers";
import QuestionsAndAnswers from "./Pages/Dashboard/QuestionsAndAnswers";
import AppointmentsDashboard from "./Pages/Dashboard/AppointmentsDashboard";
import GetDetails from "./Pages/GetDetails";
import VisualData from "./Pages/VisualData/VisualData";
import VisualDataImage from "./Pages/VisualData/VisualDataImage";
import VisualDataVideo from "./Pages/VisualData/VisualDataVideo";
import AppointmentVerification from "./Pages/Dashboard/AppointmentVerification";
import ShareDataPage from "./Pages/Dashboard/SharePage";
import NurseChat from "./Pages/VisualData/NurseChat";
import NurseChatSetup from "./Pages/VisualData/NurseChatSetup";
import NurseChatAnswers from "./Pages/VisualData/NurseChatAnswers";
import NurseChatAssessments from "./Pages/VisualData/NurseChatAssessments";
const App = () => {
  const { isAuthenticated, _user, setIsAuthenticated, setUser, token, setToken } = useContext(AuthContext);

  const fetchUser = async () => {
    if (isAuthenticated) {
      const response = await axios.get(import.meta.env.VITE_APP_BASE_API + import.meta.env.VITE_APP_API_VERSION + "/user/patient/me", {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsAuthenticated(true);
      setUser(response.data.user);
    } else {
      setIsAuthenticated(false);
      setUser({});
      setToken(null);
    }
  };
  useEffect(() => {
    fetchUser();
  }, [isAuthenticated]);

  return (
    <Router>
      {/* <Navbar /> */}
      <Routes>
        <Route path="/" element={<MainDashboard />} />
        <Route path="/appointment" element={<Appointment />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/get-details" element={<GetDetails />} />
        <Route path="/login" element={<Login />} />
        {/* New Pages */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/notifications" element={<NotificationPage />} />
        <Route path="/friends-list" element={<FriendsListPage />} />
        <Route path="/edit-profile" element={<EditProfilePage />} />
        <Route path="/user-information" element={<UserInformationPage />} />
        <Route path="/dashboard" element={<MainDashboard />} />
        <Route path="/dashboard/share-data" element={<ShareData />} />
        <Route path="/dashboard/share-data/answers" element={<AnswersDashboard />} />
        <Route path="/dashboard/share-data/answers/:id" element={<QuestionsAndAnswers />} />
        <Route path="/dashboard/share-data/list" element={<ShareDataPage />} />
        <Route path="/dashboard/appointments" element={<AppointmentsDashboard />} />
        <Route path="/visual-data" element={<VisualData />} />
        <Route path="/visual-data/image" element={<VisualDataImage />} />
        <Route path="/visual-data/video" element={<VisualDataVideo />} />
        <Route path="/appointment/:appointmentId" element={<AppointmentVerification />} />
        <Route path="/dashboard/nurse-chat" element={<NurseChat />} />
        <Route path="/dashboard/nurse-chat/setup" element={<NurseChatSetup />} />
        <Route path="/dashboard/nurse-chat/answers" element={<NurseChatAnswers />} />
        <Route path="/dashboard/nurse-chat/assessments" element={<NurseChatAssessments />} />
        <Route path="/dashboard/nurse-chat/answers/:id" element={<QuestionsAndAnswers />} />
      </Routes>
      <Footer />
      <ToastContainer position="top-center" />
      <ChatIcon />
    </Router>
  );
};

export default App;
